
<template>
  <div class="intro">
    <div class="intro-left">
      <h1>Special solutions for <br> your business</h1>
      <h1 class="tablet-h1">Special solutions for your business</h1>
        <div class="intro-text">
          <p>{{ $t('message.introText') }}</p>
        </div>
        <a href="#Kompetenzen">
          <button class="btn-moreInformation">{{ $t('message.introButton') }}</button>
        </a>
    </div>
    <div class="intro-right">
      <img class="logo" alt="DC mockup" src="../assets/img/mockup.svg">
    </div>
  </div>
</template>

<script>
export default {
  name: "introDc",
};

</script>

<style scoped>
* {
  box-sizing: border-box;
}
.intro{
  display: flex;
  min-height: 350px;
}
.intro-left{
  flex: 1;
  text-align: left;
  margin-top: 100px;
}
.intro-right{
  flex: 1;
}
.btn-moreInformation{
  color: white;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 160%;
  background-color: #DF1A12;
  border: none;
  border-radius: 8px;
  height: 56px;
  width: 207px;
  padding: 12px 28px 12px 28px;
  cursor:pointer;
}
.btn-moreInformation:hover{
  background-color: #C1160F;
}
.intro-text{
}
p{
  color: #646465;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 166.667% */
  margin: 32px 0;
}
h1{
  color: var(--Black, #212429);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Open Sans;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 66px;
  letter-spacing: -1px;
  align-self: stretch;
}
.tablet-h1{
  display: none;
}
img{
  height: 700px;
  width: 700px;
}
@media (max-width: 1260px) {
  .btn-moreInformation{
    padding: 12px 38px;
    width: 217px;
  }
  .intro{
    flex-direction: column;
    align-items: center;
    max-width: 850px;
    margin: 0 auto;
  }
  .intro-left{
    flex: 1;
    text-align: center;
  }
  img{
    height: 515px;
    width: 515px;
    margin-bottom: 64px;
  }
  h1{
    display: none;
  }
  .tablet-h1{
    display: block;
    color: var(--Black, #212429);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 66px;
    letter-spacing: -1px;
    align-self: stretch;
    margin-bottom: 20px;
  }
  p{
    font-size: 18px;
    padding-right: 10rem;
    padding-left: 10rem;
    color: #646465;
    margin-top: 20px;
    margin-bottom: 43px;
    line-height: 30px;
  }
}
@media (max-width: 768px) {
  .intro{
    flex-direction: column;
    align-items: center;
    max-width: 350px;
    margin: 0 auto;
  }
  .intro-left{
    flex: 1;
    text-align: center;
  }
  img{
    max-height: 350px;
    max-width: 350px;
    margin-bottom: 45px;
  }
  .tablet-h1{
    font-family: 'Open Sans';
    font-size: 24px;
    font-weight: normal;
    margin: 20px 0 20px 0;
    letter-spacing: 1px;
    line-height: 36px;

  }
  p{
    font-size: 16px;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .btn-moreInformation{
    width: 189px;
    height: 50px;
    padding: 12px 38px;
    font-size: 16px;
    line-height: 160%;
    text-transform: capitalize;
  }
}
</style>
