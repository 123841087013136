<template>
  <h1 id="Kompetenzen">{{ $t('message.skillsH1') }}</h1>
  <div class="container">
    <div class="row">
      <div class="half-1-img">
      <img src="../assets/img/up4u_1.png" alt="Pic with two People" class="half"/>
      </div>
        <div class="half">
        <div class="textBox">
        <h2>{{ $t('message.skills1H2')}}</h2>
        <div class="text">
          <p>{{ $t('message.skills1') }}</p>
        </div>
          <button class="btn-moreInformation" @click="toggleModal5">{{ $t('message.introButton') }}</button>
      </div>
    </div>
    </div>
    <div class="row">

      <div class="half">
        <div class="textBox">
        <h2>{{ $t('message.skills2H2')}}</h2>
        <div class="text">
          <p>{{ $t('message.skills2') }}</p>
        </div>
          <button class="btn-moreInformation" @click="toggleModal6">{{ $t('message.introButton') }}</button>
      </div>
      </div>
      <div class="half-2-img">
      <img src="../assets/img/tk5w_1.svg" alt="Clock with Woman" class="half-2"/>
      </div>
    </div>
    <div class="row">
      <div class="half-3-img">
        <img src="../assets/img/8yk6_1.png" alt="Pic with two People" class="half-3"/>
      </div>
      <div class="half">
        <div class="textBox">
        <h2>{{ $t('message.skills3H2')}}</h2>
        <div class="text">
          <p>{{ $t('message.skills3') }}</p>
        </div>
          <button class="btn-moreInformation" @click="toggleModal7">{{ $t('message.introButton') }}</button>
      </div>
    </div>
    </div>
    <div class="row">
      <div class="half">
        <div class="textBox">
        <h2>{{ $t('message.skills4H2')}}</h2>
        <div class="text">
          <p>{{ $t('message.skills4') }}</p>
        </div>
          <button class="btn-moreInformation" @click="toggleModal8">{{ $t('message.introButton') }}</button>
      </div>
      </div>
      <div class="half-4-img">
      <img src="../assets/img/nlb6_1.png" alt="Woman with Window" class="half-4"/>
      </div>
    </div>
  </div>
  <div class="card-container">
    <div class="card" @click="toggleText1">
      <div class="half-mobile">
        <div>
          <img src="../assets/img/up4u_1.png" alt="Pic with two People" class="half-mobile"/>
        </div>
        <div>
          <h2>{{ $t('message.skills1H2')}}</h2>
        </div>
      </div>
      <div class="text-content" v-if="isTextVisible1">
        <p>{{ $t('message.skills1')}}</p>
        <p>{{ $t('message.skills1_1')}}</p>
        <p>{{ $t('message.skills1_2')}}</p>
      </div>
    </div>
    <div class="card" @click="toggleText2">
      <div class="half-mobile">
        <div>
          <img src="../assets/img/tk5w_1.svg" alt="Clock with Woman" class="half-mobile"/>
        </div>
        <div>
          <h2>{{ $t('message.skills2H2M')}}</h2>
        </div>
      </div>
    <div class="text-content" v-if="isTextVisible2">
      <p>{{ $t('message.skills2')}}</p>
      <p>{{ $t('message.skills2_1')}}</p>
      <p>{{ $t('message.skills2_2')}}</p>
    </div>
    </div>
    <div class="card" @click="toggleText3">
      <div class="half-mobile">
        <div>
          <img src="../assets/img/8yk6_1.png" alt="Woman and Man ahead of schedule" class="half-mobile"/>
        </div>
        <div>
          <h2>{{ $t('message.skills3H2')}}</h2>
        </div>
      </div>
      <div class="text-content" v-if="isTextVisible3">
        <p>{{ $t('message.skills3')}}</p>
        <p>{{ $t('message.skills3_1')}}</p>
        <p>{{ $t('message.skills3_2')}}</p>
      </div>
    </div>
    <div class="card" @click="toggleText4">
      <div class="half-mobile">
        <div>
          <img src="../assets/img/nlb6_1.png" alt="Woman with Window" class="half-mobile"/>
        </div>
        <div>
          <h2>{{ $t('message.skills4H2')}}</h2>
        </div>
      </div>
      <div class="text-content" v-if="isTextVisible4">
        <p>{{ $t('message.skills4')}}</p>
        <p>{{ $t('message.skills4_1')}}</p>
        <p>{{ $t('message.skills4_2')}}</p>
      </div>
    </div>
  </div>
  <div v-if="isModalVisible5" class="modal">
    <div class="modal-content">
      <div>
        <span class="close-btn" @click="toggleModal5">&times;</span>
      </div>
      <div class="half-5-img">
        <img src="../assets/img/up4u_1.png" alt="Pic with two People" class="half-5"/>
      </div>
      <div class="textBoxModal">
        <h2>{{ $t('message.skills1H2')}}</h2>
        <p>{{ $t('message.skills1')}}</p>
        <p>{{ $t('message.skills1_1')}}</p>
        <p>{{ $t('message.skills1_2')}}</p>
      </div>
    </div>
  </div>
  <div v-if="isModalVisible6" class="modal">
    <div class="modal-content">
      <div>
        <span class="close-btn" @click="toggleModal6">&times;</span>
      </div>
      <div class="half-6-img">
        <img src="../assets/img/tk5w_1.svg" alt="Pic with two People" class="half-6"/>
      </div>
      <div class="textBoxModal">
        <h2>{{ $t('message.skills2H2')}}</h2>
        <p>{{ $t('message.skills2')}}</p>
        <p>{{ $t('message.skills2_1')}}</p>
        <p>{{ $t('message.skills2_2')}}</p>
      </div>
    </div>
  </div>
  <div v-if="isModalVisible7" class="modal">
    <div class="modal-content">
      <div>
        <span class="close-btn" @click="toggleModal7">&times;</span>
      </div>
      <div class="half-7-img">
        <img src="../assets/img/8yk6_1.png" alt="Pic with two People" class="half-7"/>
      </div>
      <div class="textBoxModal">
        <h2>{{ $t('message.skills3H2')}}</h2>
        <p>{{ $t('message.skills3')}}</p>
        <p>{{ $t('message.skills3_1')}}</p>
        <p>{{ $t('message.skills3_2')}}</p>
      </div>
    </div>
  </div>
  <div v-if="isModalVisible8" class="modal">
    <div class="modal-content">
      <div>
        <span class="close-btn" @click="toggleModal8">&times;</span>
      </div>
      <div class="half-8-img">
        <img src="../assets/img/nlb6_1.png" alt="Pic with two People" class="half-8"/>
      </div>
      <div class="textBoxModal">
        <h2>{{ $t('message.skills4H2')}}</h2>
        <p>{{ $t('message.skills4')}}</p>
        <p>{{ $t('message.skills4_1')}}</p>
        <p>{{ $t('message.skills4_2')}}</p>
      </div>
    </div>
  </div>
</template>

<style scoped>

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  justify-content: center;
  align-items: center;
  text-align: center;
}
.modal-content {
  background-color: #fefefe;
  padding: 50px;
  border: 1px solid #888;
  width: 80%;
  text-align: left;
  border-radius: 10px;
  position: relative;
  max-width: 900px;
  margin: auto;
  margin-top: 50px;
}
.close-btn {
  font-size: 70px;
  font-weight: lighter;
  cursor: pointer;
  position: absolute;
  top: 40px;
  right: 50px;
}
.close-btn:hover {
  color: #de1512;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 138px;
}
.card-container{
  display: none;
}
.card{
  display: flex;
}
h1{
  font-family: "Open Sans";
  font-size: 50px;
  margin-top: 150px;
  color: #DF1A12;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin-bottom: 60px;
}
h2{
  font-family: "Open Sans";
  color: var(--Black, #212429);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 1px;
}
p{
  color: #646465;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 1px;
  margin: 26px 0;
}
.row {
  display: flex;
  flex-direction: row;
  gap:20px;
}
.half {
  flex: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.half-1-img{
  flex: 1;
}
.half-2-img{
  flex: 1;
}
.half-3-img {
  flex: 1;
}
.half-4-img {
  flex: 1;
}
.half-5-img{
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
.half-6-img{
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
.half-7-img{
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
.half-8-img{
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
.textBox{
  max-width: 450px;
}
 img.half{
   width: 433px;
   height: 351px;
}
 img.half-2{
   width: 395px;
   height: 350px;
 }
 img.half-3{
  width: 590px;
  height: 371px;
}
 img.half-4{
  width: 560px;
  height: 350px;
}
.half-5{
  width: 516px;
  height: 418px;
}
.btn-moreInformation{
  color: #fff;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  text-transform: capitalize;
  line-height: 160%;
  background-color: #DF1A12;
  border: none;
  border-radius: 8px;
  height: 56px;
  width: 207px;
  padding: 12px 28px 12px 28px;
  cursor:pointer;
}
.btn-moreInformation:hover{
  background-color: #C1160F;
}
.textBoxModal{
  padding: 50px;
}
@media (max-width: 1260px) {
  .container {
    padding: 0;
    gap: 0;
  }
  .btn-moreInformation{
    font-style: normal;
  }
  .row {
    gap:20px;
    margin: 50px 0 50px 0;
  }
  img.half{
    width: 320px;
    height: 260px;
  }
  img.half-2{
    width: 317px;
    height: 288px;
  }
  img.half-3{
    width: 422px;
    height: 277px;
  }
  img.half-4{
    width: 370px;
    height: 232px;
  }
  .half-1-img{
    padding-left: 35px;
  }
  .half-3-img {
    padding-left: 35px;
  }
  .half-4-img {
    padding-right: 35px;
  }
  h1{
    margin: 64px 0 0 0;
  }
  h2{
    margin: 0;
  }
  p{
    font-size: 20px;
  }
  .textBox{
    padding: 0;
  }
}
@media (max-width: 768px) {
  .container {
    display: none;
  }
  .card-container{
    display: block;
    padding: 0 10px;
  }
  .card{
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    margin: 15px 0 15px 0;
    border-radius: 10px;
    padding: 20px 10px 20px 10px;
    justify-content: center;
    align-items: flex-start;
    min-height: 80px;
  }
  img.half-mobile {
    width: 100px;
    margin-right: 10px;
  }
  .half-mobile{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h1{
    font-size: 25px;
    text-align: center;
    margin: 64px 0 40px 0;
  }
  h2{
    font-size: 14px;
    margin: 0;
    text-align: left;
    word-wrap: break-word;
    overflow-wrap: break-word;
    line-height: 20px;
  }
  p{
    color: #646465;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 1px;
  }
  .text-content {
    transition: max-height 0.3s ease-out;
    text-align: left;
    font-size: 13px;
    margin-top: 15px;
    color: #646465;
  }
  .close-btn {
    font-size: 36px;
    font-weight: bold;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .close-btn:hover {
    color: #de1512;
  }
}

</style>

<script setup>
import { ref } from 'vue';

const isTextVisible1 = ref(false);
const isTextVisible2 = ref(false);
const isTextVisible3 = ref(false);
const isTextVisible4 = ref(false);
const isModalVisible5 = ref(false);
const isModalVisible6 = ref(false);
const isModalVisible7 = ref(false);
const isModalVisible8 = ref(false);
const toggleText1 = () => {
  isTextVisible1.value = !isTextVisible1.value;
};
const toggleText2 = () => {
  isTextVisible2.value = !isTextVisible2.value;
};
const toggleText3 = () => {
  isTextVisible3.value = !isTextVisible3.value;
};
const toggleText4 = () => {
  isTextVisible4.value = !isTextVisible4.value;
};
const toggleModal5 = () => {
  isModalVisible5.value = !isModalVisible5.value;
};
const toggleModal6 = () => {
  isModalVisible6.value = !isModalVisible6.value;
};
const toggleModal7 = () => {
  isModalVisible7.value = !isModalVisible7.value;
};
const toggleModal8 = () => {
  isModalVisible8.value = !isModalVisible8.value;
};
</script>
