<script setup>
import {ref} from "vue";

const isModalVisible1 = ref(false);
const isModalVisible2 = ref(false);
const isModalVisible3 = ref(false);
const isModalVisible4 = ref(false);
const toggleModal1 = () => {
  isModalVisible1.value = !isModalVisible1.value;
};
const toggleModal2 = () => {
  isModalVisible2.value = !isModalVisible2.value;
};
const toggleModal3 = () => {
  isModalVisible3.value = !isModalVisible3.value;
};

const toggleModal4 = () => {
  isModalVisible4.value = !isModalVisible4.value;
};
</script>

<template>
<h1 id="Karriere">{{ $t('message.careerH1') }}</h1>
  <div class="row">
    <img src="../assets/img/jtgc_1.png" alt="Woman and Man ahead of schedule" class="half"/>
      <div class="half">
        <h1 class="h1-bePart">Be part of our team</h1>
          <div class="-text">
            <p>{{ $t('message.careerText_1') }}</p>
            <p>{{ $t('message.careerText_2') }}</p>
          </div>
      </div>
  </div>
  <div class="row-mobile">
    <h1 class="h1-bepart">Be part of our team</h1>
      <div class="-text">
        <p class="bePart_p">{{ $t('message.careerText_1') }}</p>
      </div>
      <img src="../assets/img/jtgc_1.png" alt="Woman and Man ahead of schedule" class="half"/>
        <div class="-text">
          <p class="bePart_p">{{ $t('message.careerText_2') }}</p>
        </div>
  </div>
  <div class="job-container">
    <div class="job-container-box">
      <div class="job-title" @click="toggleModal1">
        <h2>Frontend Developer</h2>
        <span class="toggle-icon">{{ isModalVisible1 ? '×' : '+' }}</span>
      </div>
      <div class="job-details">
        <span><img src="../assets/img/clock.svg" alt="symbol for clock">Full-time or part-time</span>
        <span><img src="../assets/img/location.svg" alt="symbol for Location">Home-office</span>
        <span><img src="../assets/img/location.svg" alt="symbol for Location">Emmendingen</span>
        <span><img src="../assets/img/letter.svg" alt="symbol of schedule">Unbefristet</span>
      </div>
      <transition name="fade">
        <div v-if="isModalVisible1" class="job-description">
          <div class="textBoxModal">
            <h3>{{ $t('message.job1H3') }}:</h3>
            <p>{{ $t('message.jobText1') }}</p>
            <h3>{{ $t('message.job1_1H3') }}:</h3>
            <p>
              <ul>
                <li>{{ $t('message.jobText1_1') }}</li>
                <li>{{ $t('message.jobText1_2') }}</li>
                <li>{{ $t('message.jobText1_3') }}</li>
                <li>{{ $t('message.jobText1_4') }}</li>
                <li>{{ $t('message.jobText1_5') }}</li>
                <li>{{ $t('message.jobText1_6') }}</li>
                <li>{{ $t('message.jobText1_7') }}</li>
                <li>{{ $t('message.jobText1_8') }}</li>
              </ul>
            </p>
            <h3>{{ $t('message.job1_2H3') }}:</h3>
            <p>
              <ul>
                <li>{{ $t('message.jobText12_1') }}</li>
                <li>{{ $t('message.jobText12_2') }}</li>
                <li>{{ $t('message.jobText12_3') }}</li>
                <li>{{ $t('message.jobText12_4') }}</li>
                <li>{{ $t('message.jobText12_5') }}</li>
                <li>{{ $t('message.jobText12_6') }}</li>
              </ul>
            </p>
          </div>
          <a href="mailto:bewerbung@datacapo.com?subject=Bewerbung" class="apply-link">{{ $t('message.apply') }}</a>
        </div>
      </transition>
    </div>
      <div class="job-container-box">
        <div class="job-title" @click="toggleModal2">
          <h2>Senior Full-Stack Developer</h2>
          <span class="toggle-icon">{{ isModalVisible2 ? '×' : '+' }}</span>
        </div>
        <div class="job-details">
          <span><img src="../assets/img/clock.svg" alt="symbol for clock">Full-time or part-time</span>
          <span><img src="../assets/img/location.svg" alt="symbol for Location">Home-office</span>
          <span><img src="../assets/img/location.svg" alt="symbol for Location">Emmendingen</span>
          <span><img src="../assets/img/letter.svg" alt="symbol of schedule">Unbefristet</span>
        </div>
        <transition name="fade">
          <div v-if="isModalVisible2" class="job-description">
            <div class="textBoxModal">
              <h3>{{ $t('message.job1H3') }}:</h3>
              <p>{{ $t('message.jobText2') }}</p>
              <h3>{{ $t('message.job1_1H3') }}:</h3>
              <p>
                <ul>
                  <li>{{ $t('message.jobRequirement1') }}</li>
                  <li>{{ $t('message.jobRequirement2') }}</li>
                  <li>{{ $t('message.jobRequirement3') }}</li>
                  <li>{{ $t('message.jobRequirement4') }}</li>
                  <li>{{ $t('message.jobRequirement5') }}</li>
                </ul>
              </p>
              <h3>{{ $t('message.job1_2H3') }}:</h3>
              <p>
                <ul>
                  <li>{{ $t('message.jobBenefit1') }}</li>
                  <li>{{ $t('message.jobBenefit2') }}</li>
                  <li>{{ $t('message.jobBenefit3') }}</li>
                  <li>{{ $t('message.jobBenefit4') }}</li>
                  <li>{{ $t('message.jobBenefit5') }}</li>
                  <li>{{ $t('message.jobBenefit6') }}</li>
                </ul>
              </p>
            </div>
            <a href="mailto:bewerbung@datacapo.com?subject=Bewerbung" class="apply-link">{{ $t('message.apply') }}</a>
          </div>
        </transition>
      </div>
    <div class="job-container-box">
      <div class="job-title" @click="toggleModal4">
        <h2>Event Support</h2>
        <span class="toggle-icon">{{ isModalVisible4 ? '×' : '+' }}</span>
      </div>
      <div class="job-details">
        <span><img src="../assets/img/clock.svg" alt="symbol for clock">Part-time</span>
        <span><img src="../assets/img/location.svg" alt="symbol for Location">Emmendingen</span>
        <span><img src="../assets/img/letter.svg" alt="symbol of schedule">Unbefristet</span>
      </div>
      <transition name="fade">
        <div v-if="isModalVisible4" class="job-description">
          <div class="textBoxModal">
            <h3>{{ $t('message.job1H3') }}:</h3>
            <p>{{ $t('message.jobText4') }}</p>
            <h3>{{ $t('message.job1_2H3') }}:</h3>
            <p>
              <ul>
                <li>{{ $t('message.jobText42_1') }}</li>
                <li>{{ $t('message.jobText42_2') }}</li>
                <li>{{ $t('message.jobText42_3') }}</li>
                <li>{{ $t('message.jobText42_4') }}</li>
                <li>{{ $t('message.jobText42_5') }}</li>
                <li>{{ $t('message.jobText42_6') }}</li>
              </ul>
            </p>
          </div>
          <a href="mailto:bewerbung@datacapo.com?subject=Bewerbung" class="apply-link">{{ $t('message.apply') }}</a>
        </div>
      </transition>
    </div>
      <div class="job-container-box">
        <div class="job-title" @click="toggleModal3">
          <h2>{{ $t('message.unsolicitedApplication') }}</h2>
          <span class="toggle-icon">{{ isModalVisible3 ? '×' : '+' }}</span>
        </div>
        <div class="job-details">
          <span><img src="../assets/img/clock.svg" alt="symbol for clock">Full-time or part time</span>
          <span><img src="../assets/img/location.svg" alt="symbol for Location">Home-office</span>
          <span><img src="../assets/img/location.svg" alt="symbol for Location">Emmendingen</span>
          <span><img src="../assets/img/letter.svg" alt="symbol of schedule">Unbefristet</span>
        </div>
        <transition name="fade">
          <div v-if="isModalVisible3" class="job-description">
            <div class="textBoxModal">
              <h3>{{ $t('message.becomePartOfTeam') }}</h3>
              <p>{{ $t('message.jobOffer') }}</p>
            </div>
            <a href="mailto:bewerbung@datacapo.com?subject=Bewerbung" class="apply-link">{{ $t('message.apply') }}</a>
          </div>
          </transition>
        </div>
    </div>
</template>

<style scoped>
.fade-enter-active {
  transition: opacity 0.4s ease;
}
.fade-leave-active{
  transition: opacity 0.2s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 10%;
}
.job-container-box h2 {
  transition: color 0.2s ease;
}
.job-container-box:hover h2 {
  color: #DF1A12;
}
ul{
  padding-left: 25px;
}
span{
  border: 1px solid #000;
  padding: 8px 13px;
  margin-right: 10px;
  border-radius: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #000;
}
h1{
  color: #DF1A12;
  font-family: "Open Sans";
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  margin-top: 150px;
  margin-bottom: 60px;
}
.h1-bePart{
  margin: 0;
  color: var(--Black, #212429);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 65.5px;
  letter-spacing: 1px;
}
h2{
  color: #000;
  font-family: "Open Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin: 0;
}
p{
  color: #252525;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.bePart_p{
  color: #252525;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.row {
  display: flex;
  flex-direction: row;
}
.row-mobile{
  display: none;
}
.half {
  flex: 1;
  text-align: left;
  padding-right: 116px;
}

img.half {
  width: 470px;
  height: 407px;
}
.job-container {
  margin: 50px 0 0 0;
  text-align: left;
}
.job-title {
  Display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 32px;
  font-weight: bold;
  color: #333;
  margin: 0;
  padding: 0;
}
.job-details {
  margin: 27px 0 27px 0;
}
.job-details span img {
  vertical-align: middle;
  margin-right: 5px;
  padding-bottom: 2px;
}

.job-container-box {
  display: flex;
  flex-direction: column;
  position: relative;
  border-bottom: 1px solid #EBEBEB;
  background-color: #FFFFFF;
  box-shadow: 0 4px 28px 0 rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 24px 22px;
  margin-bottom: 20px;
  transition: color 0.2s ease;
}
.job-container-box:hover{
  background-color: #F8F9FA;
}
.desktop-line {
  stroke: #DF1A12;
  stroke-width: 3;
}
.mobile-line {
  display: none;
}
.apply-link {
  display: inline-block;
  padding: 12px 38px;
  background-color: #DF1A12;
  color: #fff;
  text-decoration: none;
  border: none;
  border-radius: 8px;
  line-height: 160%;
  width: 98px;
  height: 32px;
  font-size: 20px;
  text-align: center;
  margin-bottom: 24px;
  margin-top: 27px;
}
.apply-link:hover{
  background-color: #C1160F;
}
.toggle-icon {
  cursor: pointer;
  font-weight: bold;
  color: #DF1A12;
  border: none;
  padding: 0;
  margin: 0;
  height: 35px;
  font-size: 32px;
}
@media (max-width: 1260px) {
  h1{
    font-size: 50px;
    margin-top: 88px;
    margin-bottom: 48px;
  }
  .h1-bePart{
    margin: 0;
    font-size: 36px;
    font-weight: 500;
  }
  img.half {
    width: 304px;
    height: 266px;
    padding-left: 20px;
    padding-right: 116px;
  }
  .row{
    justify-content: center;
    align-items: center;
  }
  .half{
    flex: auto;
    padding: 0;
  }
}
@media (max-width: 768px) {
  ul{
    padding: 0 0 0 10px;
  }
  .toggle-icon {
    width: 25px;
    font-size: 20px;
  }
  .job-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .job-container-box {
    box-shadow: 0 4px 28px 0 rgba(0, 0, 0, 0.16);
    margin: 0 10px;
    gap: 0;
    padding: 5px 10px;
  }
  .job-title {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 50px;
  }
  .job-details {
    margin-bottom: 0;
    margin-top: 10px;
  }
  span {
    margin-bottom: 10px;
    display: inline-block;
    border-radius: 5px;
    font-size: 14px;
    padding: 0 13px;
  }
  .textBoxModal {
    margin-top: 50px;
  }
  .row {
    display: none;
  }
  .row-mobile {
    display: block;
    margin: 0 auto;
  }
  img.half {
    width: 278px;
    height: 240px;
    margin: 15px auto;
    padding: 0;
  }
  .job-container {
    padding: 0;
  }
  h1 {
    font-size: 25px;
    margin: 40px 10px 25px 10px;
  }
  .h1-bepart {
    font-size: 20px;
    color: black;
    text-align: left;
    margin: 25px 10px 0 0;
    padding: 0 10px;
  }
  h2 {
    color: #252525;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 19.2px */
  }

  h3 {
    font-size: 12px;
  }
  p {
    font-size: 12px;
    text-align: left;
    padding: 0;
  }
  .bePart_p {
    color: #252525;
    font-size: 16px;
    padding: 0 10px;
  }
  .mobile-line {
    display: block;
    stroke: #DF1A12;
    stroke-width: 2;
  }
  .desktop-line {
    display: none;
  }
.apply-link {
  display: inline-block;
  padding: 12px 38px;
  background-color: #DF1A12;
  color: #fff;
  text-decoration: none;
  border: none;
  border-radius: 8px;
  line-height: 160%;
  width: 79px;
  height: 26px;
  font-size: 16px;
  text-align: center;
  }
}
</style>
