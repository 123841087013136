<template>
  <div class="footer">
    <div class="box-text">
      <p>Copyright © 2024 datacapo</p>
    </div>
    <div class="box-img">
      <img v-if="showLinks" class="logo" src="../assets/img/logo_datacapo_red.svg" @click="scrollToTop">
    </div>
    <div class="box-href">
      <div>
        <router-link :to="{name: 'impressum'}">{{ $t('message.imprint') }}</router-link>
      </div>
      <div>
        <router-link :to="{name: 'datenschutz'}">{{ $t('message.privacyPolicy') }}</router-link>
      </div>
    </div>
  </div>
  <div class="footer-mobile">
    <div class="first-box">
    <div class="box-img-mobile">
      <img class="logo-mobile" src="../assets/img/logo_datacapo_red.svg" @click="scrollToTop">
    </div>
    <div class="box-href-mobile">
      <div>
        <router-link :to="{name: 'impressum'}">{{ $t('message.imprint') }}</router-link>
      </div>
      <div>
        <router-link :to="{name: 'datenschutz'}">{{ $t('message.privacyPolicy') }}</router-link>

      </div>
    </div>
    </div>
    <div class="second-box">
    <div class="box-text-mobile">
      <p>Copyright © 2023 datacapo</p>
    </div>
    </div>
  </div>
</template>

<style scoped>

p{
  color: #495057;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  text-transform: capitalize;
}
a{
  color: #495057;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  text-decoration: none;
}
a:hover{
  color: #C1160F;
}
.footer {
  display: flex;
  height: 100px;
  align-items: center;
  max-width: 1440px;
  border-top: 1px solid #BEBEBE;
}
.footer-mobile{
  display: none;
}
.box-text{
  font-size: 20px;
  flex: 1;
  text-align: left;
}
.box-img {
  flex: 1;
}
.box-href {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 40px;
}

.logo {
  width: 150px;
}
.logo:hover{
  background-color: #DDE2E5;
}

@media (max-width: 768px) {
  .footer{
    display: none;
  }
  .footer-mobile{
    display: block;
    margin: 50px 0 0 0;
    border-top: 1px solid #BEBEBE;
  }
  .first-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px 5px 10px;
  }
  .second-box{
    padding-bottom: 15px;
  }
  .logo-mobile{
    width: 98px;
    height: 36px;
    padding: 15px 0 10px 10px;
  }
  .box-text-mobile{
    font-size: 12px;
    color: black;
  }
  .box-href-mobile {
    display: flex;
    gap: 15px;
    padding-right: 10px;
    color: black;
  }
  p{
    font-size: 14px;
    margin: 0;
  }
  a{
    font-size: 14px;
  }
}
</style>
<script>
export default {
  data() {
    return {

    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  },
  props: {
    showLinks: {
      type: Boolean,
      default: true
    }
  }
}
</script>
